import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, TextStyle } from '@shopify/polaris';
import { addItemToCheckout } from '../actions/checkout';
import { setQuantity } from '../actions/product';
import { CART_PATH } from '../routes';
import NewCartModal from './NewCartModal';

class ProductActions extends Component {
  constructor(props) {
    super(props);
    this.new_cart_modal = null;
  }

  render() {
    const {
      isLoading,
      selectedVariantID,
      quantity,
      variantAvailable,
      addItemToCheckout,
      history,
      setQuantity,
      hasAttemptedToComplete
    } = this.props;

    return (
      <>
        {!variantAvailable && (
          <Card.Section>
            <TextStyle variation="negative">
              Item not available. Please choose a new option.
            </TextStyle>
          </Card.Section>
        )}
        <Card.Section subdued>
          <Button
            primary
            fullWidth
            loading={isLoading}
            disabled={!variantAvailable}
            onClick={e => {
              e.preventDefault();

              const runAddToCart = () => {
                addItemToCheckout(selectedVariantID, parseInt(quantity)).then(json => {
                  if (!json.errors) {
                    setQuantity(1);
                    history.push(CART_PATH);
                  }
                });
              };

              if (hasAttemptedToComplete && this.new_cart_modal) {
                this.new_cart_modal.open({ afterAction: runAddToCart });
              } else {
                runAddToCart();
              }
            }}
          >
            Add To Cart
          </Button>
        </Card.Section>
        <NewCartModal ref={x => (this.new_cart_modal = x)} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedVariantID: state.product.selectedVariant.id,
    quantity: state.product.quantity,
    variantAvailable: state.product.variantAvailable,
    isLoading: state.checkout.isLoading,
    hasAttemptedToComplete: state.checkout.hasAttemptedToComplete
  };
};

export default connect(
  mapStateToProps,
  { addItemToCheckout, setQuantity }
)(ProductActions);
