import { Card, FormLayout, Layout, TextField } from '@shopify/polaris';
import React, { Component } from 'react';

import _debounce from 'lodash/debounce';

export default class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    // NOTE: waiting until user stops typing for 350ms before updating redux store
    // it's a heavy operation, so every keypress would be bad for performance
    this.changed = _debounce(this.props.onChange, 350);
  }

  componentWillReceiveProps(next_props) {
    // If redux store has a different value than our local state we need to update the local state to get back in sync with store
    if (this.state.value !== next_props.value) {
      this.setState({ value: next_props.value });
    }
  }

  handleChange(new_value) {
    this.setState({ value: new_value }, () => {
      this.changed(new_value);
    });
  }

  render() {
    return (
      <Layout.Section>
        <Card sectioned>
          <FormLayout>
            <TextField
              label="Search"
              type="search"
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              helpText="Product title, description, SKU, etc."
              autoFocus
            />
          </FormLayout>
        </Card>
      </Layout.Section>
    );
  }
}
