import React, { Component } from 'react';

import { connect } from 'react-redux';
import { updatePageLoadId } from '../actions/page';
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from 'react-router';

class SSPageLoadId extends Component {
    componentDidMount() {
        // NOTE: app initial load
        this.props.updatePageLoadId(uuidv4());
    }

    componentDidUpdate(prevProps) {
        const prevPath = prevProps.location.pathname;
        const newPath = this.props.location.pathname;

        // NOTE: route changed. Generate new page load uuid (for SearchSpring)
        if (newPath && prevPath !== newPath) {
            this.props.updatePageLoadId(uuidv4());
        }
    }

    render() {
        return (
            <>
            {this.props.children}
            </>
        )
    }
};

const mapStateToProps = state => {
    return {};
};

export default withRouter(connect(
    mapStateToProps,
    { updatePageLoadId }
)(SSPageLoadId));
   