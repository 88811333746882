import React from 'react';
import { withRouter } from 'react-router';
import { Button, Page, TextContainer } from '@shopify/polaris';
import { SEARCH_PATH } from '../routes';
import BannerManagerContainer from './BannerManagerContainer';

const FourOhFour = ({ history }) => {
  return (
    <Page title="404">
      <BannerManagerContainer />
      <TextContainer>
        <p>Hmm, we can't find that page.</p>
        <Button onClick={() => history.push(SEARCH_PATH)}>Search for products</Button>
      </TextContainer>
    </Page>
  );
};

export default withRouter(FourOhFour);
