import {
  BASE_LOCATION_UPDATED,
  PRODUCT_META_UPDATED,
  PRODUCT_REQUEST_COMPLETED,
  PRODUCT_REQUEST_STARTED,
  PRODUCT_UPDATED,
  QUANTITY_UPDATED,
  SELECTED_IMAGE_UPDATED,
  SELECTED_OPTION_UPDATED,
  SELECTED_VARIANT_UPDATED,
  VARIANT_AVAILABLE_UPDATED
} from '../actions/types';

export const INITIAL_STATE = {
  product: null,
  product_meta: {},
  selectedOptions: {},
  selectedVariant: {
    price: '',
    image: {
      src: ''
    }
  },
  // inventory: null,
  variantAvailable: true,
  selectedImage: '',
  quantity: 1,
  isLoading: false,
  baseLocation: null
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case PRODUCT_REQUEST_COMPLETED:
      return {
        ...state,
        isLoading: false
      };
    case PRODUCT_UPDATED:
      return {
        ...state,
        product: action.payload.product,
        selectedVariant: action.payload.variant,
        selectedImage: action.payload.variant.image && action.payload.variant.image.id
      };
    case QUANTITY_UPDATED:
      return {
        ...state,
        quantity: action.payload
      };
    case SELECTED_VARIANT_UPDATED:
      return {
        ...state,
        selectedVariant: action.payload.variant
      };
    case SELECTED_OPTION_UPDATED:
      return {
        ...state,
        selectedOptions: {
          ...state.selectedOptions,
          ...action.payload
        }
      };
    case SELECTED_IMAGE_UPDATED:
      return {
        ...state,
        selectedImage: action.payload
      };
    case VARIANT_AVAILABLE_UPDATED:
      return {
        ...state,
        variantAvailable: action.payload
      };
    case PRODUCT_META_UPDATED:
      return {
        ...state,
        product_meta: action.payload
      };
    case BASE_LOCATION_UPDATED:
      return {
        ...state,
        baseLocation: action.payload
      };
    default:
      return { ...state };
  }
};

export default productReducer;
