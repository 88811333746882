import * as PropTypes from 'prop-types';

import {
  CART_PATH,
  LOGIN_PATH,
  PRODUCTS_PATH,
  SEARCH_PATH,
  STORE_ID_SETTING_PATH
} from '../routes';
import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AppTopBar from '../components/AppTopBar';
import AuthedRoute from '../components/AuthedRoute';
import Cart from '../components/Cart';
import FourOhFour from '../components/FourOhFour';
import { Frame } from '@shopify/polaris';
import Login from '../components/Login';
import Product from '../components/Product';
import { Provider } from 'react-redux';
import SSPageLoadId from '../components/SSPageLoadId';
import Search from '../components/Search';
import StoreIdSetting from '../components/StoreIdSetting';
import { logout_timer } from '../LogoutTimer';
import store from '../store';

class App extends Component {
  static contextTypes = {
    polaris: PropTypes.object
  };

  componentDidMount() {
    logout_timer.start();
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  render() {
    return (
      <Router>
        <Provider store={store}>
          <SSPageLoadId>
            <Frame topBar={<AppTopBar />}>
              <Route>
                {({ location }) => {
                  return (
                    <>
                      <Switch>
                        <Route path={LOGIN_PATH} component={Login} key={location.pathname} />
                        <Route
                          path={STORE_ID_SETTING_PATH}
                          component={StoreIdSetting}
                          key={location.pathname}
                        />
                        <AuthedRoute
                          path={SEARCH_PATH}
                          exact
                          component={Search}
                          key={location.pathname}
                        />
                        <AuthedRoute
                          path={`${PRODUCTS_PATH}/:handle`}
                          component={Product}
                          key={location.pathname}
                        />
                        <AuthedRoute path={CART_PATH} component={Cart} key={location.pathname} />
                        <AuthedRoute component={FourOhFour} />
                      </Switch>
                    </>
                  );
                }}
              </Route>
            </Frame>
          </SSPageLoadId>
        </Provider>
      </Router>
    );
  }
}

export default App;
