import React, { Component } from 'react';
import { connect } from 'react-redux';
import { savePersistentStoreId, removePersistentStoreId } from '../actions/auth';
import { Button, Form, FormLayout, Icon, Stack, TextField } from '@shopify/polaris';
import BannerManagerContainer from './BannerManagerContainer';
import LogoImage from '../images/logo-special-order.png';
import { LOGIN_PATH } from '../routes';

class StoreIdSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.persistentStoreID || ''
    };

    this.handleSettingsSave = this.handleSettingsSave.bind(this);
    this.handleSettingsClear = this.handleSettingsClear.bind(this);
  }

  componentDidMount() {
    // adding a body class to override Polaris-Frame background color
    // we remove this class in the AuthedRoute component for any interior routes
    document.querySelector('body').classList.add('login-route');
  }

  handleChange(value, name) {
    this.setState({
      [name]: value
    });
  }

  handleSettingsSave() {
    this.props.savePersistentStoreId(this.state.storeId);
    this.props.history.push(LOGIN_PATH);
  }

  handleSettingsClear() {
    this.props.removePersistentStoreId();
    this.handleChange('', 'storeId');
    this.props.history.push(LOGIN_PATH);
  }

  render() {
    const { persistentStoreID, history } = this.props;
    const { storeId } = this.state;

    return (
      <div className="login">
        <div className="login__section login__section--logo">
          <img src={LogoImage} alt="Glik's SOS" />
        </div>

        <BannerManagerContainer />

        <div className="login__section login__section--subheading">
          <p>Please enter a 3 digit Store ID. This will be used for all logins on this device.</p>
        </div>

        <div className="login__section login__section--form">
          <Form onSubmit={this.handleSettingsSave.bind(this)}>
            <FormLayout>
              <TextField
                value={storeId}
                onChange={this.handleChange.bind(this)}
                label="Store ID"
                labelHidden
                type="text"
                id="storeId"
                pattern="\d*"
                placeholder="Store ID"
                autoComplete={false}
                autoFocus
              />
              <Button fullWidth size="large" submit primary disabled={storeId === ''}>
                Save Default
              </Button>
              <Button fullWidth size="large" onClick={this.handleSettingsClear}>
                Clear Default
              </Button>
            </FormLayout>
          </Form>
        </div>

        <div className="login__section login__section--nav">
          <Button plain onClick={() => history.push(LOGIN_PATH)}>
            <Stack spacing="extraTight" alignment="center" distribution="center">
              <Icon source="chevronLeft" />
              <span>Back to Login</span>
            </Stack>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    persistentStoreID: state.auth.persistentStoreID
  };
};

export default connect(
  mapStateToProps,
  { savePersistentStoreId, removePersistentStoreId }
)(StoreIdSetting);
