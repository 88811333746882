import { SS_SESSION_ID_NAME, SS_USER_ID_NAME } from '../components/SearchSpringSessionIdManager';

import { Cookies } from 'react-cookie';
import { NIL as NIL_UUID } from 'uuid';
import store from '../store';

/**
 * searchspring search api requests
 */
export const getSearchResults = ({query, page = 1}) => {
  const url = `https://${process.env.SEARCHSPRING_SOS_SITE_ID || 'vd2kgf'}.a.searchspring.io/api/search/search.json?resultsFormat=native&siteId=${process.env.SEARCHSPRING_SOS_SITE_ID || 'vd2kgf'}&q=${query}&resultsPerPage=50&page=${page}`;

  const store_state = store.getState();
  const page_load_id = store_state.page.page_load_id;

  const _cookies = new Cookies();
  const cookies = _cookies.getAll();

  const ss_session_cookie = cookies[SS_SESSION_ID_NAME] || NIL_UUID;
  const ss_user_cookie = cookies[SS_USER_ID_NAME] || NIL_UUID;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'searchspring-page-load-id': page_load_id,
      'searchspring-session-id': ss_session_cookie,
      'searchspring-user-id': ss_user_cookie
    }
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      console.error(`There was an error fetching search results: ${res.statusText}`);
      throw new Error('searchapi failed');
    }
  });
}
