import { SOS_PERSISTENT_STORE_ID, getItem } from './localStorage';
import { applyMiddleware, compose, createStore } from 'redux';
import { loadState, saveState } from './sessionStorage';

import combinedReducer from '../reducers';
import { logout_timer } from '../LogoutTimer';
import thunk from 'redux-thunk';

// get persisted state
let persistedState = loadState();

// inject our persistent store id
if (persistedState && persistedState.auth) {
  persistedState = Object.assign({}, persistedState, {
    auth: { ...persistedState.auth, persistentStoreID: getItem(SOS_PERSISTENT_STORE_ID) }
  });
} else {
  persistedState = { auth: { persistentStoreID: getItem(SOS_PERSISTENT_STORE_ID) } };
}

// create our store from reducers, persisted state, and any middleware needed
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(combinedReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

// subscribe to any changes to state, and save it to sessionStorage
store.subscribe(() => {
  let { checkout, auth, product } = store.getState();

  // NOTE: on any state change, reset our logout timer
  logout_timer.reset();

  saveState({
    checkout: {
      checkout: checkout.checkout,
      hasAttemptedToComplete: checkout.hasAttemptedToComplete
    },
    auth,
    product
  });
});

export default store;
