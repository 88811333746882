import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from '../actions/auth';
import { Button, Form, FormLayout, Icon, Stack, TextField } from '@shopify/polaris';
import { SEARCH_PATH, STORE_ID_SETTING_PATH } from '../routes';
import BannerManagerContainer from './BannerManagerContainer';
import LogoImage from '../images/logo-special-order.png';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeId: '',
      ringId: '',
      show_store_id_input: props.persistentStoreID ? false : true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.toggleStoreInputView = this.toggleStoreInputView.bind(this);
  }

  componentDidMount() {
    // adding a body class to override Polaris-Frame background color
    document.querySelector('body').classList.add('login-route');
  }

  handleChange(value, name) {
    this.setState({
      [name]: value
    });
  }

  toggleStoreInputView() {
    this.setState({ show_store_id_input: !this.state.show_store_id_input });
  }

  handleLogin(e) {
    e.preventDefault();
    const { storeId, ringId, show_store_id_input } = this.state,
      store_id_to_submit = show_store_id_input ? storeId : this.props.persistentStoreID;

    this.props.login({ storeId: store_id_to_submit, ringId });
  }

  render() {
    const { isLoggedIn, persistentStoreID, history } = this.props;
    const { storeId, ringId, show_store_id_input } = this.state;

    if (isLoggedIn) {
      return <Redirect to={SEARCH_PATH} />;
    }

    return (
      <div className="login">
        <div className="login__section login__section--logo">
          <img src={LogoImage} alt="Glik's SOS" />
        </div>

        <BannerManagerContainer />

        <div className="login__section login__section--form">
          <Form onSubmit={this.handleLogin}>
            <FormLayout>
              {show_store_id_input && (
                <TextField
                  value={storeId}
                  onChange={this.handleChange}
                  label="Store ID"
                  labelHidden
                  placeholder="Store ID"
                  type="text"
                  id="storeId"
                  pattern="\d*"
                  autoComplete={false}
                  autoFocus
                />
              )}

              <TextField
                value={ringId}
                onChange={this.handleChange}
                label="Ring ID"
                labelHidden
                type="password"
                id="ringId"
                pattern="\d*"
                placeholder="Ring ID"
                autoComplete={false}
                autoFocus={!show_store_id_input}
              />
              <Button
                submit
                primary
                fullWidth
                size="large"
                disabled={ringId === '' || (show_store_id_input && storeId === '')}
              >
                Login
              </Button>
            </FormLayout>
          </Form>
        </div>

        <div className="login__section login__section--nav">
          {persistentStoreID ? (
            <>
              {!show_store_id_input && (
                <p>
                  You're logging in to <strong>STORE #{persistentStoreID}</strong>
                </p>
              )}

              <Button plain onClick={this.toggleStoreInputView}>
                <Stack spacing="extraTight" alignment="center" distribution="center">
                  <span>{show_store_id_input ? 'Use Default Store' : 'Override'}</span>
                  {!show_store_id_input && <Icon source="chevronRight" />}
                </Stack>
              </Button>
            </>
          ) : (
            <Button plain onClick={() => history.push(STORE_ID_SETTING_PATH)}>
              Set Default Store ID
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    store: state.auth.store,
    persistentStoreID: state.auth.persistentStoreID
  };
};

export default connect(
  mapStateToProps,
  { login }
)(Login);
