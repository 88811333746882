import {
  SEARCH_PRODUCTS_UPDATED,
  SEARCH_QUERY_UPDATED,
  SEARCH_REQUEST_COMPLETED,
  SEARCH_REQUEST_STARTED
} from '../actions/types';

export const INITIAL_STATE = {
  query: '',
  products: { 
    breadcrumbs: [],
    facets: [],
    filterSummary: [],
    merchandising: {},
    pagination: {},
    resultLayout: '',
    results: [],
    sorting: {},

    initialBlankState: true
  },
  isLoading: false
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_QUERY_UPDATED:
      return {
        ...state,
        query: action.payload
      };
    case SEARCH_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case SEARCH_REQUEST_COMPLETED:
      return {
        ...state,
        isLoading: false
      };
    case SEARCH_PRODUCTS_UPDATED:
      return {
        ...state,
        products: action.payload
      };
    default:
      return { ...state };
  }
};

export default searchReducer;
