import {
  AUTH_QUERY_STARTED,
  AUTH_QUERY_COMPLETED,
  AUTH_UPDATED,
  CLEAR_CHECKOUT,
  PERSIST_STORE_ID,
  REMOVE_PERSISTENT_STORE_ID
} from './types';
import { auth_query } from '../queries/auth';
import { createNewCheckout } from '../actions/checkout';
import { removeItem, setItem, SOS_PERSISTENT_STORE_ID } from '../store/localStorage';
import { logout_timer } from '../LogoutTimer';
import { showBanner, killBanner } from '../actions/banner';

export const login = ({ storeId, ringId }) => dispatch => {
  dispatch({
    type: AUTH_QUERY_STARTED
  });

  return auth_query({ storeId, ringId })
    .then(json => {
      if (json && !json.errors && json.user && json.user.id && json.store && json.store.id) {
        dispatch({
          type: AUTH_UPDATED,
          payload: {
            isLoggedIn: true,
            user: json.user,
            user_store: json.store
          }
        });

        // NOTE: just logged in, so get a new checkout
        dispatch(createNewCheckout());
        logout_timer.start();
      } else {
        dispatch(
          showBanner({
            title: 'Login error',
            message: 'Please try again.',
            status: 'critical',
            dismissable: true,
            autohide: true,
            autofocus: false
          })
        );
      }
    })
    .then(() => {
      dispatch({
        type: AUTH_QUERY_COMPLETED
      });
    });
};

export const logout = () => dispatch => {
  dispatch({
    type: AUTH_UPDATED,
    payload: {
      user: null,
      user_store: null,
      isLoggedIn: false
    }
  });

  dispatch({
    type: CLEAR_CHECKOUT
  });

  dispatch(killBanner());

  logout_timer.stop();
};

export const savePersistentStoreId = store_id => dispatch => {
  // NOTE: save store id for future use (only updates redux store)
  dispatch({
    type: PERSIST_STORE_ID,
    payload: {
      store_id: store_id
    }
  });
  // IMPORTANT: this does the actual backup of store number to localStorage
  setItem(SOS_PERSISTENT_STORE_ID, store_id);
};

export const removePersistentStoreId = () => dispatch => {
  dispatch({
    type: REMOVE_PERSISTENT_STORE_ID
  });
  // IMPORTANT: this removes the store number from localStorage
  removeItem(SOS_PERSISTENT_STORE_ID);
};
