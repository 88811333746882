import { execute, makePromise } from 'apollo-link';

import { HttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';

const STOREFRONT_GRAPHQL_URL = `${process.env.REACT_APP_STOREFRONT_URL || 'https://gliks-main.myshopify.com'}/api/2023-07/graphql.json`;

const link = new HttpLink({
  uri: STOREFRONT_GRAPHQL_URL,
  headers: {
    'Content-Type': 'application/graphql',
    'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN || 'e4d4b7dd84b29dee25ce38eccbf56aac'
  }
});

export const storefront_query = (body, variables) => {
  let operation = {};
  operation.query = gql`
    ${body}
  `;

  // add in variables to our operate if we have any
  if (typeof variables != 'undefined') {
    operation.variables = variables;
  }

  return makePromise(execute(link, operation));
};
