import { logout } from './actions/auth';
import store from './store';


const debug = false;

class LogoutTimer {
  constructor () {
    this.TARGET_TIME = 300000; // 60 seconds ; 5min = 300000
    this.INTERVAL = 60000; // 1 second ; 1min = 60000
    this.elapsed = 0;
    this.timer = null;
    if (debug) { console.warn('TIMER constructor'); }
  }

  start () {
    if (debug) { console.warn('TIMER started'); }
    if (this.timer) { clearInterval(this.timer); }
    this.elapsed = 0;
    this.timer = setInterval(() => {
        let store_state;

        if (this.elapsed >= this.TARGET_TIME) {
            if (debug) { console.warn('TIMER ended'); }
            
            this.stop();

            store_state = store.getState();
            
            if (store_state.auth.isLoggedIn) {
              store.dispatch(logout());
            }

        } else {
            this.elapsed += this.INTERVAL;
        }
    }, this.INTERVAL);
  }

  stop () {
    if (debug) { console.warn('TIMER stop'); }
    if (this.timer) { 
        clearInterval(this.timer);
        this.timer = null;
        this.elapsed = 0;
    }
  }

  reset () {
    if (debug) { console.warn('TIMER reset'); }
    this.elapsed = 0;
  }
}

export const logout_timer = new LogoutTimer();
