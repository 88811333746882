import { NIL as NIL_UUID } from 'uuid';
import { PAGE_LOAD_ID_UPDATED } from '../actions/types';

export const INITIAL_STATE = {
  page_load_id: NIL_UUID
};

const pageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGE_LOAD_ID_UPDATED:
      return {
        ...state,
        page_load_id: action.payload
      };
    default:
      return { ...state };
  }
};

export default pageReducer;