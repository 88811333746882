import {
  Badge,
  Banner,
  Caption,
  Card,
  Layout,
  Pagination,
  ResourceList,
  Stack,
  TextStyle,
  Thumbnail
} from '@shopify/polaris';
import React, { Component } from 'react';
import { formatMoney, priceAsNumbers } from './utils';

import { PRODUCTS_PATH } from '../routes';
import _first from 'lodash/first';
import _last from 'lodash/last';
import { connect } from 'react-redux';
import { doSearch } from '../actions/search';
import { updateProductMeta } from '../actions/product';
import { withRouter } from 'react-router';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickPrevious = this.handleClickPrevious.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.availableProducts = this.availableProducts.bind(this);
  }

  goToProduct(item) {
    this.props.updateProductMeta({
      gliks_vendor_style: item.mfield_gliks_vendor_style,
      gliks_style_code: item.mfield_gliks_gliks_style_code
    });
    this.props.history.push(`${PRODUCTS_PATH}/${item.handle}`);
  }

  renderItem(item, id) {
    const vendor_style = item.mfield_gliks_vendor_style;
    const gliks_style_code = item.mfield_gliks_gliks_style_code;

    return (
      <ResourceList.Item
        id={id}
        accessibilityLabel={`View detail for ${item.name}`}
        media={<Thumbnail source={item.thumbnailImageUrl || item.imageUrl} />}
        onClick={() => this.goToProduct(item)}
      >
        <div className="search-result-item">
          <div className="search-result-item__section">
            <h3 className="search-result-item__title">
              <TextStyle variation="strong">{item.name}</TextStyle>
            </h3>

            <Stack vertical spacing="extraTight">
              {vendor_style && (
                <Caption>
                  <TextStyle variation="strong">Vendor Style: </TextStyle>
                  {vendor_style}
                </Caption>
              )}
              {gliks_style_code && (
                <Caption>
                  <TextStyle variation="strong">Glik's Style: </TextStyle>
                  {gliks_style_code}
                </Caption>
              )}
            </Stack>
          </div>

          <div className="search-result-item__section">
            <div>
              <TextStyle variation="subdued">From:</TextStyle>{' '}
              <TextStyle variation="positive">
                {/* NOTE: item is searchspring results, not Shopify storefront api. So price is actual value */}
                {formatMoney(priceAsNumbers(item.price))}
              </TextStyle>
            </div>
          </div>
        </div>
      </ResourceList.Item>
    );
  }

  handleClickNext() {
    const { doSearch, query, page } = this.props;

    doSearch({ query: query, page: page + 1 }).then(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.props.history.push(`?query=${query}&page=${page + 1}`);
    });
  }

  handleClickPrevious() {
    const { doSearch, query, page } = this.props;

    doSearch({ query: query, page: page - 1 }).then(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
      this.props.history.push(`?query=${query}&page=${page - 1}`);
    });
  }

  availableProducts(products) {
    return products.filter(p => !p.ss_tags.includes('Unavailable') && !p.ss_tags.includes('unavailable'))
  }

  render() {
    const {
      isLoading,
      isBlankSearchState,
      pages,
      page,
      products,
      total_products,
      hits_per_page
    } = this.props;
    const availProducts = this.availableProducts(products);
    let product_page_diff = 0;

    if (availProducts.length && total_products >= hits_per_page) {
      product_page_diff = hits_per_page - availProducts.length;
    }

    return isBlankSearchState ? null : (
      <>
        <Layout.Section>
          <Card>
            {availProducts.length ? (
              <Card.Section>
                {total_products >= hits_per_page ? (
                  <div>
                    <TextStyle variation="subdued">
                      Showing {hits_per_page * (page - 1) + 1} -{' '}
                      {hits_per_page * page - product_page_diff} of {total_products}
                    </TextStyle>
                  </div>
                ) : (
                  <div>
                    <TextStyle variation="subdued">
                      Showing {availProducts.length * page} of {total_products}
                    </TextStyle>
                  </div>
                )}
              </Card.Section>
            ) : null}

            <ResourceList
              hasMoreItems={true}
              items={availProducts}
              resourceName={{ singular: 'product', plural: 'products' }}
              renderItem={this.renderItem}
              loading={isLoading}
            />
            {!availProducts.length && !isLoading && (
              <Banner status="info" title="No Products Found" dismissable={false} />
            )}
          </Card>
        </Layout.Section>
        {availProducts.length && pages > 1 ? (
          <Layout.Section>
            <Stack alignment="center">
              <Pagination
                hasPrevious={page > 1}
                hasNext={page < pages}
                onPrevious={this.handleClickPrevious}
                onNext={this.handleClickNext}
              />

              <div>
                <TextStyle variation="subdued">
                  {page} of {pages}
                </TextStyle>
              </div>
            </Stack>
          </Layout.Section>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    query: state.search.query,
    products: state.search.products.results,
    total_products: state.search.products.pagination.totalResults,
    hits_per_page: state.search.products.pagination.perPage,
    pages: state.search.products.pagination.totalPages,
    page: state.search.products.pagination.currentPage,
    isLoading: state.search.isLoading,
    isBlankSearchState: state.search.products.initialBlankState
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { doSearch, updateProductMeta }
  )(SearchResults)
);
