import {
  CHECKOUT_QUERY_STARTED,
  CHECKOUT_QUERY_COMPLETED,
  CHECKOUT_CREATED,
  CHECKOUT_UPDATED,
  CHECKOUT_COMPLETE_ATTEMPTED,
  CLEAR_CHECKOUT_COMPLETE_ATTEMPTED
} from './types';
import { showBanner, killBanner } from '../actions/banner';
import { createCheckout, replaceLineItems } from '../queries/checkout';

export const createNewCheckout = () => (dispatch, getState) => {
  dispatch({
    type: CHECKOUT_QUERY_STARTED
  });

  const _state = getState(),
    current_user = _state.auth.user,
    current_store = _state.auth.user_store,
    custom_attrs = [
      {
        key: 'sos_store_id',
        value: current_store.id + '' // IMPORTANT: must always be a string value for graphql
      },
      {
        key: 'sos_store_number',
        value: current_store.number + '' // IMPORTANT: must always be a string value for graphql
      },
      {
        key: 'sos_store_name',
        value: current_store.name + ''
      },
      {
        key: 'sos_ring_id',
        value: current_user.number + '' // IMPORTANT: must always be a string value for graphql
      },
      {
        key: 'sos_associate_name',
        value: `${current_user.first_name} ${current_user.last_name}`
      },
      {
        key: '_sos_store_details', // NOTE: this is passing along the store location, so the "ship to store" script in checkout can pre-fill/select this store without searching.
        value: JSON.stringify({
          // IMPORTANT: formatting data to match checkout's "ship to store" api format
          id: current_store.id,
          info: {
            location: {
              city: current_store.city,
              country: 'United States',
              state: current_store.state,
              street: current_store.address1,
              street2: current_store.address2,
              zip: current_store.zip,
              latitude: current_store.latitude,
              longitude: current_store.longitude
            },
            name: current_store.name,
            number: current_store.number,
            store_location_descriptor:
              current_store.address1 +
              ',' +
              current_store.city +
              ',' +
              current_store.state +
              ',' +
              current_store.zip
          },
          latitude: current_store.latitude,
          longitude: current_store.longitude
        })
      }
    ];

  return createCheckout(custom_attrs)
    .then(json => {
      if (
        !json ||
        json.errors ||
        (json.data &&
          json.data.checkoutCreate &&
          json.data.checkoutCreate.checkoutUserErrors &&
          json.data.checkoutCreate.checkoutUserErrors.length)
      ) {
        dispatch(
          showBanner({
            title: 'Cart error',
            message:
              'There was a problem creating a new cart. If this error continues to happen, please try logging out and login again.',
            status: 'critical',
            dismissable: false,
            autohide: false,
            autofocus: true,
            primaryAction: {
              content: 'Try creating new cart',
              onAction: () => {
                dispatch(killBanner());
                dispatch(createNewCheckout());
              }
            }
          })
        );
      } else {
        dispatch({
          type: CHECKOUT_CREATED,
          payload: json.data.checkoutCreate.checkout
        });
      }
    })
    .then(() => {
      dispatch({
        type: CHECKOUT_QUERY_COMPLETED
      });
    });
};

export const flagCheckoutCompleteAttempted = () => (dispatch, getState) => {
  dispatch({
    type: CHECKOUT_COMPLETE_ATTEMPTED
  });
};

export const clearCheckoutCompleteAttempted = () => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CHECKOUT_COMPLETE_ATTEMPTED
  });
};

export const addItemToCheckout = (variantId, quantity) => (dispatch, getState) => {
  const customAttributes = [{ key: '_sos_product', value: 'true' }, { key: '_shipping', value: 'shipped' }];
  let { checkout } = getState().checkout,
    lineItems = checkout.lineItems.edges,
    newLineItems = [],
    foundMatch = false;

  // NOTE: keep all existing line items, update quantity if we match on the passed in variantId
  lineItems.forEach(lineItem => {
    newLineItems = newLineItems.concat({
      customAttributes,
      variantId: lineItem.node.variant.id,
      quantity:
        lineItem.node.variant.id == variantId
          ? parseInt(lineItem.node.quantity) + parseInt(quantity)
          : lineItem.node.quantity
    });

    if (lineItem.node.variant.id == variantId) {
      foundMatch = true;
    }
  });

  // NOTE: after updating, if we didnt match then add the new product
  if (!foundMatch) {
    newLineItems = newLineItems.concat({ customAttributes, variantId, quantity });
  }

  dispatch({
    type: CHECKOUT_QUERY_STARTED
  });

  return replaceLineItems(newLineItems, checkout.id)
    .then(json => {
      if (json.errors) {
        dispatch(
          showBanner({
            title: 'Cart error',
            message: 'There was a problem adding to the cart. Please try again',
            status: 'warning',
            dismissable: true,
            autohide: true,
            autofocus: true
          })
        );
        console.error(json.errors);
      } else {
        dispatch({
          type: CHECKOUT_UPDATED,
          payload: json.data.checkoutLineItemsReplace.checkout
        });
      }
      return json;
    })
    .then(json => {
      dispatch({
        type: CHECKOUT_QUERY_COMPLETED
      });
      return json;
    });
};

export const updateLineItemQuantity = (variantId, quantity) => (dispatch, getState) => {
  let { checkout } = getState().checkout,
    lineItems = checkout.lineItems.edges,
    newLineItems = [];

  newLineItems = lineItems.map(lineItem => {
    return {
      customAttributes: [{ key: '_sos_product', value: 'true' }, { key: '_shipping', value: 'shipped' }],
      variantId: lineItem.node.variant.id,
      quantity:
        lineItem.node.variant.id == variantId
          ? parseInt(quantity)
          : parseInt(lineItem.node.quantity)
    };
  });

  // filter out items that are quantity: 0
  newLineItems = newLineItems.filter(item => item.quantity > 0);

  dispatch({
    type: CHECKOUT_QUERY_STARTED
  });

  return replaceLineItems(newLineItems, checkout.id)
    .then(json => {
      if (json.errors) {
        dispatch(
          showBanner({
            title: 'Cart error',
            message: 'There was a problem updating the cart.',
            status: 'warning',
            dismissable: true,
            autohide: true,
            autofocus: false
          })
        );
        console.error(json.errors);
      } else {
        dispatch({
          type: CHECKOUT_UPDATED,
          payload: json.data.checkoutLineItemsReplace.checkout
        });
      }
      return json;
    })
    .then(json => {
      dispatch({
        type: CHECKOUT_QUERY_COMPLETED
      });
      return json;
    });
};
