import { validate as uuidValidate, version as uuidVersion } from 'uuid';

export function priceAsNumbers (variant_price) {
    // NOTE: Converts string to number and keeps the correct "cents" value. 
    // Idea taken from Shopify's Buy Button js code that calculates line items in the cart
    if (typeof variant_price === 'string') { variant_price *= 100; }
    
    return variant_price;
}


// Shopify.formatMoney - based on https://gist.github.com/stewartknapman/8d8733ea58d2314c373e94114472d44c
export function formatMoney (cents, format) {
    if (typeof cents === 'string') { cents = cents.replace('.',''); }
    
    const default_format = '${{amount}}',
        placeholder_regex = /\{\{\s*(\w+)\s*\}\}/,
        defaultOption = (opt, def) => {
                return typeof opt === 'undefined' ? def : opt;
        },
        formatWithDelimiters = (number, precision, thousands, decimal) => {
            precision = defaultOption(precision, 2);
            thousands = defaultOption(thousands, ',');
            decimal = defaultOption(decimal, '.');

            if (isNaN(number) || number == null) { return 0; } // IMPORTANT: double equal for type coercion, also isNaN might be unreliable but this is how the original Shopify method was written

            number = (number/100.0).toFixed(precision);

            let parts = number.split('.'),
                dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
                cents = parts[1] ? (decimal + parts[1]) : '';

            return dollars + cents;
        };

    let value = '', 
        format_string = format || default_format;
    
    switch (format_string.match(placeholder_regex)[1]) {
        case 'amount':
            value = formatWithDelimiters(cents, 2);
            break;
        case 'amount_no_decimals':
            value = formatWithDelimiters(cents, 0);
            break;
        case 'amount_with_comma_separator':
            value = formatWithDelimiters(cents, 2, '.', ',');
            break;
        case 'amount_no_decimals_with_comma_separator':
            value = formatWithDelimiters(cents, 0, '.', ',');
            break;
        case 'amount_with_apostrophe_separator':
            value = formatWithDelimiters(cents, 2, '\'', '.');
            break;
    }

    return format_string.replace(placeholder_regex, value);
}


export function uuidValidateV4 (uuid) {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}
