import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { LOGIN_PATH } from '../routes';

class AuthedRoute extends Component {
  render() {
    const { component: Component, checkout, isLoggedIn, ...rest } = this.props;

    // add a class to body if we're on a login route
    document.querySelector('body').classList.toggle('login-route', !isLoggedIn);

    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    checkout: state.checkout.checkout
  };
};

export default connect(mapStateToProps)(AuthedRoute);
