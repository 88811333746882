import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TopBar } from '@shopify/polaris';
import { logout } from '../actions/auth';

class AppTopBar extends Component {
  state = {
    userMenuOpen: false
  };

  toggleUserMenu = () => {
    this.setState({
      userMenuOpen: !this.state.userMenuOpen
    });
  };

  render() {
    const { logout, user, user_store, isLoggedIn } = this.props;
    const { state, toggleUserMenu } = this;
    const { userMenuOpen } = state;

    // IMPORTANT: no top bar when user is logged out!
    if (!isLoggedIn) {
      return null;
    }

    const quickPickup = () => {
      window.location = "/quick_pickup"
    }

    const userMenuMarkup = (
      <TopBar.UserMenu
        actions={[
          {
            items: [{ content: 'QuickPickup', onAction: quickPickup },{ content: 'Logout', icon: 'logOut', onAction: logout }]
          }
        ]}
        name={`${user.first_name} ${user.last_name}`}
        detail={`Glik's Store #${user_store.number}`}
        initials={`${user.first_name.charAt(0)} ${user.last_name.charAt(0)}`}
        open={userMenuOpen}
        onToggle={toggleUserMenu}
      />
    );

    return <TopBar showNavigationToggle={false} userMenu={userMenuMarkup} />;
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    user_store: state.auth.user_store,
    isLoggedIn: state.auth.isLoggedIn
  };
};

export default connect(
  mapStateToProps,
  { logout }
)(AppTopBar);
