import {
  BASE_LOCATION_UPDATED,
  PRODUCT_META_UPDATED,
  PRODUCT_REQUEST_COMPLETED,
  PRODUCT_REQUEST_STARTED,
  PRODUCT_UPDATED,
  QUANTITY_UPDATED,
  SELECTED_IMAGE_UPDATED,
  SELECTED_OPTION_UPDATED,
  SELECTED_VARIANT_UPDATED,
  VARIANT_AVAILABLE_UPDATED
} from './types';
import { getLocations, getProductByHandle } from '../queries/product';

import { INITIAL_STATE as PRODUCT_INITIAL_STATE } from '../reducers/product';
import { showBanner } from '../actions/banner';

export const getBaseLocation = () => dispatch => {
  return getLocations().then( json => {
    if ( json && !json.errors && json.locations ) {
      dispatch({
        type: BASE_LOCATION_UPDATED,
        payload: json.locations[0]
      });
    }
  });
}

export const getProduct = handle => dispatch => {
  dispatch({
    type: PRODUCT_REQUEST_STARTED
  });

  return getProductByHandle(handle)
    .then(json => {
      if (json && !json.errors && json.data && json.data.productByHandle) {
        let product = json.data.productByHandle;

        dispatch({
          type: PRODUCT_UPDATED,
          payload: { product: product, variant: product.variants.edges[0].node }
        });
      } else {
        dispatch(
          showBanner({
            title: 'Product error',
            message: 'There was a problem getting the product data.',
            status: 'warning',
            dismissable: true,
            autohide: true,
            autofocus: false
          })
        );
      }
    })
    .then(() => {
      dispatch({
        type: PRODUCT_REQUEST_COMPLETED
      });
    });
};

export const updateProductMeta = metafields => dispatch => {
  dispatch({
    type: PRODUCT_META_UPDATED,
    payload: metafields
  });
};

export const clearProductMeta = () => dispatch => {
  dispatch({
    type: PRODUCT_META_UPDATED,
    payload: PRODUCT_INITIAL_STATE.product_meta
  });
};

export const setSelectedOption = (name, value) => dispatch => {
  dispatch({
    type: SELECTED_OPTION_UPDATED,
    payload: { [name]: value }
  });
};

export const setSelectedVariant = variant => dispatch => {
  dispatch({
    type: SELECTED_VARIANT_UPDATED,
    payload: { variant }
  });
};

export const setSelectedImage = image => dispatch => {
  dispatch({
    type: SELECTED_IMAGE_UPDATED,
    payload: image
  });
};

export const setVariantAvailable = isAvailable => dispatch => {
  dispatch({
    type: VARIANT_AVAILABLE_UPDATED,
    payload: isAvailable
  });
};

export const setQuantity = quantity => dispatch => {
  dispatch({
    type: QUANTITY_UPDATED,
    payload: quantity
  });
};
