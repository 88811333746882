export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('sos_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('sos_state', serializedState);
  } catch (err) {
    // dont worry about it, if we don't save, we'll get it next time :)
  }
};
