import { Card, Select } from '@shopify/polaris';
import React, { Component } from 'react';
import {
  setSelectedImage,
  setSelectedOption,
  setSelectedVariant,
  setVariantAvailable
} from '../actions/product';

import { connect } from 'react-redux';
import { getInventoryByStyleCode } from '../queries/product'

class ProductOptions extends Component {
  constructor(props) {
    super(props);

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.getInventory = this.getInventory.bind(this);

    this.state = {
      inventoryData: null,
    }
  }

  componentDidMount () {
    this.getInventory();
  }

  componentDidUpdate(prevProps) {
    const { product_meta } = this.props;

    if ( prevProps.product_meta && prevProps.product_meta.gliks_style_code ) {
      if ( product_meta && product_meta.gliks_style_code !== prevProps.product_meta.gliks_style_code ) {
        this.getInventory();
      } else if ( this.state.inventoryData === null ) {
        this.getInventory()
      }
    } else {
      if ( !this.state.inventoryData ) {
        this.getInventory();
      }
    }
  }

  getInventory() {
    if ( this.props.product_meta ) {
      try {
        let styleCodes = this.props.product_meta.gliks_style_code;
        
        getInventoryByStyleCode(this.props.baseLocation.id, styleCodes)
        .then(json => {
          if ( json.products && json.products[0] ) {
            this.setState({inventoryData: json.products[0] });

            this.props.product.options.forEach(option => {
              this.handleOptionChange(option.values[0], option.name);
            });
          } else {
            this.props.setVariantAvailable(false);
          }
        })
        .catch(e => console.error(e));
      } catch(e) {
        console.error(e);
        this.props.setVariantAvailable(false);
      }
    } else {
      this.props.setVariantAvailable(false);
    }
  }

  handleOptionChange(value, option_name) {
    let { selectedOptions } = this.props;
    let selectedVariantInventory = null;
    let selectedVariantInventoryAvailable = null;
    selectedOptions[option_name] = value;

    const selectedVariant = this.props.product.variants.edges.find(variant => {
      return variant.node.selectedOptions.every(selectedOption => {
        return selectedOptions[selectedOption.name] === selectedOption.value.valueOf();
      });
    });

    this.props.setSelectedOption(option_name, value);
    if (selectedVariant) {
      try {
        selectedVariantInventory = this.state.inventoryData.variants.filter(variant => variant.in_house_upc === selectedVariant.node.sku);
        selectedVariantInventoryAvailable = selectedVariantInventory && selectedVariantInventory[0] && selectedVariantInventory[0].shopify_inventory_quantity > 0;
        this.props.setVariantAvailable(selectedVariantInventoryAvailable);
      } catch(e) {
        console.error(e);
        this.props.setVariantAvailable(false);
      }

      this.props.setSelectedVariant(selectedVariant.node);
      if (selectedVariant.node.image) {
        this.props.setSelectedImage(selectedVariant.node.image.id);
      }
    } else {
      this.props.setVariantAvailable(false);
    }
  }

  render() {
    const { product, selectedOptions } = this.props;

    return product.options.map(option => {
      const selectOptions = option.values.map(value => {
        return {
          label: value,
          value: value
        };
      });

      return selectOptions.length > 1 ? (
        <Card.Section title={option.name} key={option.name}>
          <Select
            label={option.name}
            id={option.name}
            labelHidden
            options={selectOptions}
            onChange={this.handleOptionChange}
            value={selectedOptions[option.name]}
          />
        </Card.Section>
      ) : null;
    });
  }
}

const mapStateToProps = state => {
  return {
    product: state.product.product,
    selectedOptions: state.product.selectedOptions,
    product_meta: state.product.product_meta,
    baseLocation: state.product.baseLocation
  };
};

export default connect(
  mapStateToProps,
  {
    setSelectedVariant,
    setSelectedOption,
    setSelectedImage,
    setVariantAvailable
  }
)(ProductOptions);
