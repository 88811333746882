import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Button, Card, Image, Link, Stack, Thumbnail } from '@shopify/polaris';
import React, { Component } from 'react';

import { Carousel } from 'react-responsive-carousel';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { setSelectedImage } from '../actions/product';

class ProductGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAllThumbs: false
    };

    this.toggleShowAllThumbs = this.toggleShowAllThumbs.bind(this);
  }

  toggleShowAllThumbs() {
    this.setState({
      showAllThumbs: !this.state.showAllThumbs
    });
  }

  render() {
    const { images, selectedImage } = this.props;
    const { showAllThumbs } = this.state;
    return (
      <Card.Section>
        <div className="product-gallery-image">
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            emulateTouch
            dynamicHeight={true}
            selectedItem={images.findIndex(image => image.node.id == selectedImage)}
          >
            {images.map(image => {
              return <Image key={image.node.id} source={image.node.transformedSrc} />;
            })}
          </Carousel>
        </div>
        <Stack spacing="extraTight">
          {images.map((image, index) => {
            if (index > 23 && !showAllThumbs) {
              return null;
            }
            return (
              <Link key={image.node.id} onClick={() => this.props.setSelectedImage(image.node.id)}>
                <Thumbnail
                  size="small"
                  source={image.node.transformedSrc}
                  alt={image.node.altText}
                />
              </Link>
            );
          })}
        </Stack>
        {images.length > 24 && (
          <Stack distribution="center">
            <Button outline size="slim" onClick={this.toggleShowAllThumbs}>
              View {showAllThumbs ? 'Less' : 'More'} Images
            </Button>
          </Stack>
        )}
      </Card.Section>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedImage: state.product.selectedImage,
    images: !_isEmpty(state.product.product) ? state.product.product.images.edges : []
  };
};

export default connect(
  mapStateToProps,
  { setSelectedImage }
)(ProductGallery);
