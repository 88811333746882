
export const SOS_PERSISTENT_STORE_ID = 'SOS_PERSISTENT_STORE_ID'; 


export const getItem = (key) => {
  try {
    const item = localStorage.getItem(key);

    return item || undefined;
  } catch (err) {
    return undefined;
  }
};

export const setItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    // TODO?
  }
};

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // TODO?
  }
};