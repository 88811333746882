import {
  CHECKOUT_QUERY_STARTED,
  CHECKOUT_QUERY_COMPLETED,
  CHECKOUT_CREATED,
  CHECKOUT_UPDATED,
  CHECKOUT_COMPLETE_ATTEMPTED,
  CLEAR_CHECKOUT_COMPLETE_ATTEMPTED,
  CLEAR_CHECKOUT
} from '../actions/types';

export const INITIAL_STATE = {
  checkout: {
    lineItems: []
  },
  isLoading: false,
  hasAttemptedToComplete: false
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT_QUERY_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case CHECKOUT_QUERY_COMPLETED:
      return {
        ...state,
        isLoading: false
      };
    case CHECKOUT_CREATED:
      return {
        ...state,
        hasAttemptedToComplete: false,
        checkout: action.payload
      };
    case CHECKOUT_UPDATED:
      return {
        ...state,
        checkout: action.payload
      };
    case CHECKOUT_COMPLETE_ATTEMPTED:
      return {
        ...state,
        hasAttemptedToComplete: true
      };
    case CLEAR_CHECKOUT_COMPLETE_ATTEMPTED:
      return {
        ...state,
        hasAttemptedToComplete: false
      };
    case CLEAR_CHECKOUT:
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};

export default checkoutReducer;
