// auth
export const AUTH_QUERY_STARTED = 'AUTH_QUERY_STARTED';
export const AUTH_QUERY_COMPLETED = 'AUTH_QUERY_COMPLETED';
export const AUTH_UPDATED = 'AUTH_UPDATED';
export const PERSIST_STORE_ID = 'PERSIST_STORE_ID';
export const REMOVE_PERSISTENT_STORE_ID = 'REMOVE_PERSISTENT_STORE_ID';

// checkout
export const CHECKOUT_QUERY_STARTED = 'CHECKOUT_QUERY_STARTED';
export const CHECKOUT_QUERY_COMPLETED = 'CHECKOUT_QUERY_COMPLETED';
export const CHECKOUT_CREATED = 'CHECKOUT_CREATED';
export const CHECKOUT_UPDATED = 'CHECKOUT_UPDATED';
export const CHECKOUT_COMPLETE_ATTEMPTED = 'CHECKOUT_COMPLETE_ATTEMPTED';
export const CLEAR_CHECKOUT_COMPLETE_ATTEMPTED = 'CLEAR_CHECKOUT_COMPLETE_ATTEMPTED';
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT';
// export const CHECKOUT_UPDATE_ADDRESS_STARTED = 'CHECKOUT_UPDATE_ADDRESS_STARTED';
// export const CHECKOUT_UPDATE_ADDRESS_SUCCESS = 'CHECKOUT_UPDATE_ADDRESS_SUCCESS';
// export const CHECKOUT_UPDATE_ADDRESS_COMPLETED = 'CHECKOUT_UPDATE_ADDRESS_COMPLETED';

// search
export const SEARCH_QUERY_UPDATED = 'SEARCH_QUERY_UPDATED';
export const SEARCH_REQUEST_STARTED = 'SEARCH_REQUEST_STARTED';
export const SEARCH_REQUEST_COMPLETED = 'SEARCH_REQUEST_COMPLETED';
export const SEARCH_PRODUCTS_UPDATED = 'SEARCH_PRODUCTS_UPDATED';

// product
export const PRODUCT_REQUEST_STARTED = 'PRODUCT_REQUEST_STARTED';
export const PRODUCT_REQUEST_COMPLETED = 'PRODUCT_REQUEST_COMPLETED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const PRODUCT_META_UPDATED = 'PRODUCT_META_UPDATED';
export const SELECTED_VARIANT_UPDATED = 'SELECTED_VARIANT_UPDATED';
export const SELECTED_OPTION_UPDATED = 'SELECTED_OPTION_UPDATED';
export const SELECTED_IMAGE_UPDATED = 'SELECTED_IMAGE_UPDATED';
export const VARIANT_AVAILABLE_UPDATED = 'VARIANT_AVAILABLE_UPDATED';
export const QUANTITY_UPDATED = 'QUANTITY_UPDATED';
export const BASE_LOCATION_UPDATED = 'BASE_LOCATION_UPDATED';

// banner
export const SHOW_BANNER = 'SHOW_BANNER';
export const KILL_BANNER = 'KILL_BANNER';

// page
export const PAGE_LOAD_ID_UPDATED = 'PAGE_LOAD_ID_UPDATED';