import { AUTH_QUERY_STARTED, AUTH_QUERY_COMPLETED, AUTH_UPDATED, PERSIST_STORE_ID, REMOVE_PERSISTENT_STORE_ID } from '../actions/types';

export const INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
  user_store: null,
  isLoading: false,
  persistentStoreID: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_QUERY_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case AUTH_QUERY_COMPLETED:
      return {
        ...state,
        isLoading: false
      };
    case AUTH_UPDATED:
      return {
        ...state,
        user: action.payload.user,
        user_store: action.payload.user_store,
        isLoggedIn: action.payload.isLoggedIn
      };
    case PERSIST_STORE_ID:
    	return {
    		...state,
    		persistentStoreID: action.payload.store_id
    	};
    case REMOVE_PERSISTENT_STORE_ID:
    	return {
    		...state,
    		persistentStoreID: null
    	};
    default:
      return { ...state };
  }
};

export default authReducer;
