import {
  SEARCH_PRODUCTS_UPDATED,
  SEARCH_QUERY_UPDATED,
  SEARCH_REQUEST_COMPLETED,
  SEARCH_REQUEST_STARTED
} from '../actions/types';

import { INITIAL_STATE } from '../reducers/search';
import _trim from 'lodash/trim';
import { getSearchResults } from '../queries/searchapi';
import { showBanner } from '../actions/banner';

export const updateSearchQuery = query => dispatch => {
  dispatch({
    type: SEARCH_QUERY_UPDATED,
    payload: query
  });
};

export const doSearch = options => dispatch => {
  dispatch({
    type: SEARCH_REQUEST_STARTED
  });

  // TODO legacy code from algolia search - not the same on searchspring. Might need to revisit
  let defaultOptions = {
    filters: 'inventory_quantity > 0', // NOTE: This was added to resolve Glik's request to only show items with stock. Remove to show all.
    facetFilters: ['published_on_storefront:true'] // NOTE: This was added to filter Remove to show all.
  };

  options = { ...options, ...defaultOptions };

  if (_trim(options.query) === '') {
    dispatch({
      type: SEARCH_PRODUCTS_UPDATED,
      payload: INITIAL_STATE.products
    });
    dispatch({ type: SEARCH_REQUEST_COMPLETED });
  } else {
    return getSearchResults(options)
      .then(json => {        
        dispatch({
          type: SEARCH_PRODUCTS_UPDATED,
          payload: json
        });
      })
      .then(() => {
        dispatch({
          type: SEARCH_REQUEST_COMPLETED
        });
      }).catch(() => {
        dispatch(
          showBanner({
            title: 'Search error',
            message: 'There was a problem searching. Please try again.',
            status: 'warning',
            dismissable: true,
            autohide: true,
            autofocus: false
          })
        );

        dispatch({
          type: SEARCH_REQUEST_COMPLETED
        });
      })
  }
};
