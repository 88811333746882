import React from 'react';
import { Card } from '@shopify/polaris';
import { connect } from 'react-redux';

const ProductDescription = ({ product }) => {
  return (
    <Card.Section title="Description">
      <p>{product.description}</p>
    </Card.Section>
  );
};

const mapStateToProps = state => {
  return {
    product: state.product.product
  };
};

export default connect(mapStateToProps)(ProductDescription);
