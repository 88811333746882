import { storefront_query } from './base';

/**
 * Fetch inventory for a product
 *
 * @param {integer} locationId - id of the base location
 * @param {array} styleCodes - array of product style codes to check for
 */
export const getInventoryByStyleCode = (locationId, styleCodes) => {
  const url = `/api/inventory.json`;
  return fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      store: locationId,
      style_code: styleCodes
    })
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      console.error(`There was an error fetching inventory: ${res.statusText}`);
    }
  });
}

/**
 * Fetch locations
 *
 * @param {integer} locationId - id of the base location
 * @param {array} styleCodes - array of product style codes to check for
 */
export const getLocations = () => {
  const url = '/api/locations.json';
  return fetch(url).then(res => {
    if (res.ok)  {
      return res.json();
    } else {
      console.error(`There was an error fetching locations: ${res.statusText}`);
    }
  })
}

/**
 * Get a single product node by handle
 *
 * @param {String} handle The handle of the desired product
 */
export const getProductByHandle = handle => {
  return storefront_query(`{
    productByHandle(handle: "${handle}" ) {
      id
      title
      availableForSale
      handle
      description
      productType
      vendor
      options {
        name
        values
      }
      variants(first: 250) {
        edges {
          node {
            id
            availableForSale
            title
            selectedOptions {
              name
              value
            }
            image {
              transformedSrc(maxWidth:550)
              altText
              id
            }
            price {
              amount
              currencyCode
            }
            sku
          }
        }
      }
      images(first: 250) {
        edges {
          node {
            transformedSrc(maxWidth:550)
            altText
            id
          }
        }
      }
    }
  }
  `);
};

/**
 * Search products by title
 *
 * @param {String} query The search terms you'd like to query
 * @param {String} direction - (optional) One of `before` or `after`, designated direction of cursor
 * @param {String} cursor - (optional) Cursor that markes the current location of desired node
 */
export const searchProductsByTitle = (query, direction, cursor) => {
  let pagination = `${direction}:"${cursor}"`,
    firstOrLast = 'first';

  if (direction === 'before') {
    firstOrLast = 'last';
  }

  if (direction === null || direction === undefined || (cursor === null || cursor === undefined)) {
    pagination = '';
  }

  return storefront_query(`{
    products(query: "${query}" ${firstOrLast}:10  ${pagination}){
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          handle
          title
          images(first:1){
            edges{
              node{
                transformedSrc(maxWidth:150)
                altText
              }
            }
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }`);
};
