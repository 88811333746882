import { SHOW_BANNER, KILL_BANNER } from './types';


export function killBanner () {
	return {
		type: KILL_BANNER
	};
}

export function showBanner (payload) {
	return {
		type: SHOW_BANNER,
		payload: payload
	};
}
