import { Card, Layout, Page, Spinner, Stack } from '@shopify/polaris';
import React, { Component } from 'react';
import { clearProductMeta, getProduct } from '../actions/product';

import BannerManagerContainer from './BannerManagerContainer';
import { CART_PATH } from '../routes';
import ProductActions from './ProductActions';
import ProductDescription from './ProductDescription';
import ProductGallery from './ProductGallery';
import ProductMeta from './ProductMeta';
import ProductOptions from './ProductOptions';
import ProductQuantity from './ProductQuantity';
import _find from 'lodash/find';
import { connect } from 'react-redux';
import { getSearchResults } from '../queries/searchapi';
import { updateProductMeta } from '../actions/product';

class Product extends Component {
  componentDidMount() {
    const { product, match, product_meta, getProduct, updateProductMeta } = this.props;

    const _searchForMeta = function () {      
      if (match.params.handle) {
        getSearchResults({query: match.params.handle}).then((json) => {
          const result_match = json.results.find((x) => x.handle === match.params.handle);

          if (result_match) {
            updateProductMeta({
              gliks_vendor_style: result_match.mfield_gliks_vendor_style,
              gliks_style_code: result_match.mfield_gliks_gliks_style_code
            });
          }
        });
      } else {
        console.error('No handle to use in meta search');
      }
    };


    // don't refetch product if we already have it
    if (!product || (product && product.handle !== match.params.handle)) {
      getProduct(match.params.handle).then(_searchForMeta);
    } else if (!product_meta || (product_meta && !product_meta.gliks_style_code || !product_meta.gliks_vendor_style)) {
      _searchForMeta();
    }
  }

  componentWillUnmount() {
    this.props.clearProductMeta();
  }

  render() {
    const { history, product, isLoading, match } = this.props;
    let page_title = '';

    if (!isLoading && product && product.handle === match.params.handle) {
      page_title = product.title;
    }

    return (
      <Page
        title={page_title}
        breadcrumbs={[{ content: 'Back', onAction: history.goBack }]}
        primaryAction={[
          {
            content: 'View Cart',
            onAction: () => history.push(CART_PATH)
          }
        ]}
      >
        <BannerManagerContainer />
        <Layout>
          {isLoading ? (
            <Layout.Section>
              <Stack alignment="center" distribution="center">
                <Spinner size="large" color="teal" />
              </Stack>
            </Layout.Section>
          ) : (
            <>
              <Layout.Section>
                {product && product.handle === match.params.handle ? (
                  <Card>
                    <ProductGallery />
                    <ProductDescription />
                  </Card>
                ) : null}
              </Layout.Section>

              <Layout.Section secondary>
                {product && product.handle === match.params.handle ? (
                  <Card>
                    <ProductMeta />
                    {product.options && <ProductOptions />}
                    <ProductQuantity />
                    <ProductActions history={history} />
                  </Card>
                ) : null}
              </Layout.Section>
            </>
          )}
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.product.product,
    isLoading: state.product.isLoading,
    product_meta: state.product.product_meta
  };
};

export default connect(
  mapStateToProps,
  { getProduct, clearProductMeta, updateProductMeta }
)(Product);
