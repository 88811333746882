import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Heading,
  Link,
  Modal,
  ResourceList,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail
} from '@shopify/polaris';
import _debounce from 'lodash/debounce';
import { updateLineItemQuantity } from '../actions/checkout';
import { PRODUCTS_PATH } from '../routes';
import { formatMoney, priceAsNumbers } from './utils';

class CartLineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 0,
      modalOpen: false
    };

    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
    this.debouncedUpdateQuantity = _debounce(this.updateQuantity.bind(this), 750);
    this.toggleModal = this.toggleModal.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
  }

  componentDidMount() {
    this.setState({
      quantity: this.props.item.quantity
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.quantity != this.props.item.quantity &&
      prevProps.item.quantity != this.props.item.quantity
    ) {
      this.setState({
        quantity: this.props.item.quantity
      });
    }
  }

  updateQuantity() {
    if (this.state.quantity != '') {
      this.props.updateLineItemQuantity(this.props.item.variant.id, this.state.quantity);
    }
  }

  handleChangeQuantity(value) {
    if (value == 0) {
      this.toggleModal();
    } else {
      this.setState({
        quantity: value
      });
      this.debouncedUpdateQuantity();
    }
  }

  confirmRemove() {
    this.toggleModal();
    this.props.updateLineItemQuantity(this.props.item.variant.id, 0);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  render() {
    const { id, quantity, title, variant } = this.props.item;

    const media = (
      <Thumbnail alt={variant.image && variant.image.alt} size="medium" source={variant.image && variant.image.transformedSrc} />
    );

    let variant_title = '';

    if (variant.title && variant.title.toLowerCase() !== 'default title') {
      variant_title = variant.title;
    }

    return (
      <>
        <Modal
          open={this.state.modalOpen}
          onClose={this.toggleModal}
          size="medium"
          primaryAction={{
            content: 'Remove Item',
            onAction: this.confirmRemove
          }}
          secondaryActions={[
            {
              content: 'Nevermind',
              onAction: this.toggleModal
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure you want to remove this item?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>

        <ResourceList.Item id={id} media={media}>
          <Heading>
            <Link
              onClick={() => this.props.history.push(`${PRODUCTS_PATH}/${variant.product.handle}`)}
            >
              {title}
            </Link>
          </Heading>
          {variant_title ? <p>{variant_title}</p> : null}
          <div className="cart-row">
            <div className="cart-quantity">
              <TextField
                label="Quantity"
                labelHidden
                value={this.state.quantity}
                type="number"
                max="999999"
                min="0"
                onChange={this.handleChangeQuantity}
                connectedRight={
                  <Button outline destructive onClick={this.toggleModal} icon="delete" />
                }
              />
            </div>
            <div className="cart-price">
              <TextStyle variation="strong">
                {formatMoney(quantity * priceAsNumbers(variant.price.amount))}
              </TextStyle>
            </div>
          </div>
        </ResourceList.Item>
      </>
    );
  }
}

export default withRouter(
  connect(
    null,
    { updateLineItemQuantity }
  )(CartLineItem)
);
