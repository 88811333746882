import React, { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { uuidValidateV4 } from './utils';
import { v4 as uuidv4 } from 'uuid';

export const SS_SESSION_ID_NAME = 'ssSessionIdNamespace';
export const SS_USER_ID_NAME = 'ssUserId';

// Generates new "session" and "user" uuids for searchspring everytime a user logs into app (if the cookies don't exist). Technically, it runs everytime the "Search" component is rendered
// https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Search-API-Request
const SearchSpringSessionIdManager = () => {
    const [cookies, setCookie] = useCookies([SS_SESSION_ID_NAME, SS_USER_ID_NAME]);

    useEffect(() => {
        const session_cookie = cookies[SS_SESSION_ID_NAME];
        const user_cookie = cookies[SS_USER_ID_NAME];

        if (!session_cookie || !uuidValidateV4(session_cookie)) {
            setCookie(SS_SESSION_ID_NAME, uuidv4(), {path: '/', secure: true});
        } 

        if (!user_cookie || !uuidValidateV4(user_cookie)) {
            setCookie(SS_USER_ID_NAME, uuidv4(), {path: '/', secure: true});
        } 
    }, []);
  
    return null;
  };
  
  export default SearchSpringSessionIdManager;