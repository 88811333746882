import { Badge, Caption, Card, DisplayText, TextStyle } from '@shopify/polaris';
import { formatMoney, priceAsNumbers } from './utils';

import React from 'react';
import { connect } from 'react-redux';

const ProductMeta = ({ product, product_meta, price }) => {
  return (
    <>
      {price && (
        <Card.Section title="Price">
          <DisplayText size="large">{formatMoney(priceAsNumbers(price.amount))}</DisplayText>
        </Card.Section>
      )}
      {product_meta && product_meta.gliks_vendor_style && (
        <Card.Section>
          <Caption>
            <TextStyle variation="strong">Vendor Style: </TextStyle>
            <Badge>{product_meta.gliks_vendor_style}</Badge>
          </Caption>
        </Card.Section>
      )}
      {product_meta && product_meta.gliks_style_code && (
        <Card.Section>
          <Caption>
            <TextStyle variation="strong">Glik's Style: </TextStyle>
            <Badge>{product_meta.gliks_style_code}</Badge>
          </Caption>
        </Card.Section>
      )}
      {product.type && (
        <Card.Section title="Type">
          <p>{product.type}</p>
        </Card.Section>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    product: state.product.product,
    price: state.product.selectedVariant.price,
    product_meta: state.product.product_meta
  };
};

export default connect(mapStateToProps)(ProductMeta);
