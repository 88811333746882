import authReducer from './auth';
import bannerReducer from './banner';
import checkoutReducer from './checkout';
import { combineReducers } from 'redux';
import pageReducer from './page';
import productReducer from './product';
import searchReducer from './search';

const combinedReducer = combineReducers({
  auth: authReducer,
  banner: bannerReducer,
  search: searchReducer,
  checkout: checkoutReducer,
  product: productReducer,
  page: pageReducer
});

export default combinedReducer;
