const AUTH_URL = '/sos/login.json';

export const auth_query = body => {
  return fetch(AUTH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      console.error(`There was an error logging in: ${res.statusText}`);
    }
  });
};

// TODO GRM - logout ajax request to kill session on logout
