import { CART_PATH, SEARCH_PATH } from '../routes';
import { Layout, Page } from '@shopify/polaris';
import React, { Component } from 'react';
import { doSearch, updateSearchQuery } from '../actions/search';

import BannerManagerContainer from './BannerManagerContainer';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import SearchSpringSessionIdManager from './SearchSpringSessionIdManager';
import { connect } from 'react-redux';
import { getBaseLocation } from '../actions/product';
import queryString from 'query-string';

class Search extends Component {
  constructor(props) {
    super(props);

    this.hydrateFromQueryParams = this.hydrateFromQueryParams.bind(this);
  }

  componentDidMount() {
    this.hydrateFromQueryParams();
    this.props.getBaseLocation();
  }

  componentDidUpdate(prevProps) {
    const { query, page } = queryString.parse(this.props.location.search);

    // only search if we have a new query
    if (prevProps.query != query || prevProps.page != page ) {
      // this.props.search(query, direction, cursor);
      this.props.doSearch({ query: query, page: page });
    }
  }

  hydrateFromQueryParams() {
    const { query } = queryString.parse(this.props.location.search);
    if (query !== null) {
      this.props.updateSearchQuery(query);
    }
  }

  handleOnChange (value) {
    this.props.updateSearchQuery(value);
    this.props.history.push(value ? `?query=${value}` : SEARCH_PATH);
    this.props.doSearch({query: value});
  }

  render() {
    const { query, history } = this.props;

    return (
      <Page
        title="Search"
        primaryAction={[
          {
            content: 'View Cart',
            onAction: () => history.push(CART_PATH)
          }
        ]}
      >
        <BannerManagerContainer />
        <Layout>
          <SearchInput value={query} onChange={this.handleOnChange.bind(this)} />
          <SearchResults />
          <SearchSpringSessionIdManager />
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    query: state.search.query
  };
};

export default connect(
  mapStateToProps,
  { updateSearchQuery, doSearch, getBaseLocation }
)(Search);
