import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, DisplayText, Layout, Page, ResourceList } from '@shopify/polaris';
import CartLineItem from './CartLineItem';
import NewCartModal from './NewCartModal';
import { SEARCH_PATH } from '../routes';
import { createNewCheckout, flagCheckoutCompleteAttempted } from '../actions/checkout';
import { logout_timer } from '../LogoutTimer';
import BannerManagerContainer from './BannerManagerContainer';
import { formatMoney, priceAsNumbers } from './utils';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.new_cart_modal = null;
  }

  componentDidMount() {
    if (this.props.hasAttemptedToComplete && this.new_cart_modal) {
      this.new_cart_modal.open();
    }

    logout_timer.reset();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.hasAttemptedToComplete &&
      !prevProps.hasAttemptedToComplete &&
      this.new_cart_modal
    ) {
      this.new_cart_modal.open();
    }
  }

  render() {
    const {
      history,
      items,
      checkoutUrl,
      subtotal,
      isLoading,
      search_query,
      search_page,
      flagCheckoutCompleteAttempted
    } = this.props;

    let search_url = SEARCH_PATH;

    if (search_query) {
      search_url += `?query=${search_query}`;

      if (search_page) {
        search_url += `&page=${search_page}`;
      }
    }

    return (
      <Page
        title="Cart"
        breadcrumbs={[{ content: 'Back', onAction: history.goBack }]}
        primaryAction={[
          {
            icon: 'search',
            content: 'Search products',
            onAction: () => history.push(search_url)
          }
        ]}
      >
        <BannerManagerContainer />
        <Layout>
          {!items.length ? (
            <Layout.Section>
              <Card title="Cart is empty" sectioned>
                <Button icon="search" onClick={() => history.push(search_url)}>
                  Search products
                </Button>
              </Card>
            </Layout.Section>
          ) : (
            <>
              <Layout.Section>
                <Card>
                  <ResourceList
                    resourceName={{
                      singular: 'Cart Item',
                      plural: 'Cart Items'
                    }}
                    items={items}
                    renderItem={item => <CartLineItem item={item.node} />}
                    loading={isLoading}
                  />
                </Card>

                <div className="cart-reset-btn">
                  <Button
                    onClick={() => {
                      if (confirm('Are you sure? Resetting the cart cannot be undone.')) {
                        this.props.createNewCheckout();
                      }
                    }}
                  >
                    Reset cart
                  </Button>
                </div>
              </Layout.Section>
              <Layout.Section secondary>
                <Card title="Subtotal">
                  <Card.Section>
                    <DisplayText size="large">{formatMoney(priceAsNumbers(subtotal))}</DisplayText>
                  </Card.Section>
                  <Card.Section>
                    <Button
                      primary
                      fullWidth
                      external={true}
                      onClick={() => {
                        flagCheckoutCompleteAttempted();
                        window.open(checkoutUrl);
                      }}
                    >
                      Checkout
                    </Button>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </>
          )}

          <NewCartModal ref={x => (this.new_cart_modal = x)} />
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.checkout.checkout.lineItems.edges,
    checkoutUrl: state.checkout.checkout.webUrl,
    subtotal: state.checkout.checkout.subtotalPrice.amount,
    isLoading: state.checkout.isLoading,
    hasAttemptedToComplete: state.checkout.hasAttemptedToComplete,
    search_query: state.search.query,
    search_page: state.search.products.page
  };
};

export default connect(
  mapStateToProps,
  { createNewCheckout, flagCheckoutCompleteAttempted }
)(Cart);
