import React, { Component } from 'react';
import { Card, TextField } from '@shopify/polaris';
import { connect } from 'react-redux';
import { setQuantity } from '../actions/product';

class ProductQuantity extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.setQuantity(value);
  }

  render() {
    return (
      <Card.Section title="Quantity">
        <TextField
          label="Quantity"
          labelHidden
          value={this.props.quantity}
          type="number"
          max="999999"
          min="1"
          onChange={this.handleChange}
        />
      </Card.Section>
    );
  }
}

const mapStateToProps = state => {
  return {
    quantity: state.product.quantity
  };
};

export default connect(
  mapStateToProps,
  { setQuantity }
)(ProductQuantity);
