import { SHOW_BANNER, KILL_BANNER, AUTH_QUERY_STARTED} from '../actions/types';

export const INITIAL_STATE = {
  banner_data: null
};

const bannerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_BANNER:
      return {
        ...state,
        banner_data: action.payload
      };
    case KILL_BANNER: /* falls through */
    case AUTH_QUERY_STARTED:
      return {
        ...state,
        banner_data: null
      };
    default:
      return { ...state };
  }
};

export default bannerReducer;
