import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, TextContainer, Heading } from '@shopify/polaris';
import { createNewCheckout, clearCheckoutCompleteAttempted } from '../actions/checkout';

/* 
@summary A modal that appears when a checkout has been attempted to be completed. Will show automatically on cart page after checkout attempt, will show on other pages when user interaction happens

*/


class NewCartModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    };

    this.open = this.open.bind(this);
    this.reopenExistingCart = this.reopenExistingCart.bind(this);
    this.startNewCart = this.startNewCart.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  open (options) {

    // NOTE: this is kind of fragile. The last thing to pass in the afterAction is the only one that will run, but currently this app only uses once instance or cart modal on product pages where this is needed.
    if (options && options.afterAction && typeof options.afterAction === 'function') {
      this.afterAction = options.afterAction;
    }

    this.setState({
      modalOpen: true
    });
  }

  reopenExistingCart () {
    this.props.clearCheckoutCompleteAttempted();
    this.toggleModal();

    if (this.afterAction) {
      this.afterAction();
    }
  }

  // NOTE: we refer to the "checkout" obj as the cart in the ui.
  startNewCart () {
    this.props.createNewCheckout().then(() => {
      this.toggleModal();

      // TODO GRM - check for errors first
      if (this.afterAction) {
        this.afterAction();
      }
    });
  }

  render() {
    return <Modal
      open={this.state.modalOpen}
      onClose={this.reopenExistingCart}
      size="medium"
      primaryAction={{
        content: 'Start new cart',
        onAction: this.startNewCart
      }}
      secondaryActions={[{
        content: 'Re-open existing',
        onAction: this.reopenExistingCart
      }]}
    >
      <Modal.Section>
        <TextContainer>
          <Heading>You have an existing cart.</Heading>
          <p>Would you like to re-open and continue editing, or start a new cart?</p>
        </TextContainer>
      </Modal.Section>
    </Modal>;
  }
}


const mapStateToProps = state => {
  return {
    
  };
};

export default connect(
  mapStateToProps,
  { createNewCheckout, clearCheckoutCompleteAttempted },
  null,
  {forwardRef: true}
)(NewCartModal);