import React, { Component } from 'react';
import { connect } from 'react-redux';
import { killBanner } from '../actions/banner';
import { Banner } from '@shopify/polaris';

/**
 * BannerManagerContainer
 * @summary container for all main app banners
 * @version 0.1.0
 * @since 0.1.0
 * @author Matthew Grimm <matt@rehash.com>
 * @copyright (c) 2019, Rehash
 */

const mapStateToProps = (state, ownProps) => {
  return {
    banner: state.banner.banner_data
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dismissBanner: () => {
      dispatch(killBanner());
    }
  };
};

class BannerManager extends Component {
  componentDidMount() {
    this.focusBannerIfPossible();
    this.startTimerIfNeeded();
  }

  componentDidUpdate() {
    this.focusBannerIfPossible();
    this.startTimerIfNeeded();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.banner !== nextProps.banner && this.banner_timer) {
      clearTimeout(this.banner_timer);
      this.banner_timer = null;
    }
  }

  startTimerIfNeeded() {
    const BANNER_KILL_TIME = 10000;

    if (this.props.banner && this.props.banner.autohide) {
      this.banner_timer = setTimeout(() => {
        this.props.dismissBanner();
      }, BANNER_KILL_TIME);
    }
  }

  focusBannerIfPossible() {
    if (this.props.banner && this.props.banner.autofocus && this.banner_manager_el.children) {
      // NOTE: was seeing a problem with mounting timing, sometimes the focus call didn't do anything, waiting until next frame seems to help
      setTimeout(() => {
        // NOTE: hacky but the only reliable way we have access to the banner dom node currently
        this.banner_manager_el.children[0].focus();
      }, 0);
    }
  }

  render() {
    const banner = this.props.banner;
    let banner_props;

    if (banner) {
      banner_props = {
        title: banner.title,
        status: banner.status,
        action: banner.primaryAction,
        secondaryAction: banner.secondaryAction
      };

      if (banner.dismissable) {
        banner_props.onDismiss = this.props.dismissBanner.bind(this);
      }
    }

    return (
      <div
        className="banner-manager"
        ref={el => {
          this.banner_manager_el = el;
        }}
      >
        {banner ? (
          <Banner {...banner_props}>
            {Array.isArray(banner.message) ? (
              <ul>
                {banner.message.map((msg, indx) => {
                  return <li key={indx}>{msg}</li>;
                })}
              </ul>
            ) : (
              banner.message
            )}

            {banner.errors_list ? (
              <ul>
                {banner.errors_list.map((msg, indx) => {
                  return <li key={indx}>{msg}</li>;
                })}
              </ul>
            ) : null}
          </Banner>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const BannerManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerManager);

export default BannerManagerContainer;
