import '../scss/index';
import 'stylesheets/application'

import App from '../components/App';
import { AppProvider } from '@shopify/polaris';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Link } from 'react-router-dom';
import LogoImage from '../images/logo.png';
import React from 'react';
import ReactDOM from 'react-dom';
import { SEARCH_PATH } from '../routes';

document.addEventListener('DOMContentLoaded', () => {
  const theme = {
    colors: {
      topBar: {
        background: '#fff',
        backgroundLighter: '#fff',
        color: '#272727'
      }
    },
    logo: {
      width: 124,
      topBarSource: LogoImage,
      url: SEARCH_PATH,
      accessibilityLabel: "Glik's"
    }
  };

  ReactDOM.render(
    <AppProvider linkComponent={Link} theme={theme}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </AppProvider>,
    document.getElementById('root')
  );
});
