import { storefront_query } from './base';

const lineItem = `
  edges {
    node {
      id
      quantity
      title
      variant {
        id
        title
        image {
          transformedSrc(maxWidth:150)
          altText
          id
        }
        price {
          amount
          currencyCode
        }
        product {
          handle
        }
      }
    }
  }
`;

const checkout = `
  id
  lineItems(first:250) {
    ${lineItem}
  }
  subtotalPrice {
    amount
    currencyCode
  }
  webUrl
  completedAt
  createdAt
  ready
  customAttributes {
    key,
    value
  }
`;

/**
 * Create a new Checkout object
 */
export const createCheckout = custom_attributes => {
  return storefront_query(
    `
    mutation checkoutCreate($customAttrs: [AttributeInput!]) {
      checkoutCreate(input:{
        customAttributes: $customAttrs
      }){
        checkoutUserErrors{
          message
          field
        }
        checkout {
          ${checkout}
        }
      }
    }
  `,
    {
      customAttrs: custom_attributes
    }
  );
};

export const getCheckout = checkoutId => {
  return storefront_query(`
    query {
      node(id: "${checkoutId}") {
        ... on Checkout {
          id
          lineItems(first:250) {
            ${lineItem}
          }
          subtotalPrice {
            amount
            currencyCode
          }
          webUrl
          completedAt
          createdAt
          ready
        }
      }
    }
  `);
};

export const updateCheckoutAttr = (checkoutId, attributes) => {
  return storefront_query(
    `
    mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
      checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
        checkoutUserErrors {
          code
          field
          message
        }
        checkout {
          ${checkout}
        }
      }
    }
  `,
    {
      checkoutId: checkoutId,
      input: attributes
    }
  );
};

export const replaceLineItems = (lineItems, checkoutId) => {
  return storefront_query(
    `
    mutation checkoutLineItemsReplace($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
      checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
        checkout {
          ${checkout}
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
    { lineItems, checkoutId }
  );
};
